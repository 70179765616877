.addressmodalBlock {
  padding: 25px;

  h2 {
    font-size: 20px;
  }

  .searchBlock {
    box-shadow: 3px 3px 3px #0000001a;
    border: 1px solid #000000;
    border-radius: 15px;
    padding: 12px 25px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 17px;

    @media screen and (max-width: 767px) {
      padding: 12px 18px;
      margin-bottom: 17px;
    }

    img {
      margin-right: 10px;
      max-width: 18px;
      width: 100%;
    }

    input {
      background: none;
      border: none;
      width: 100%;
      font-size: 15px;
      color: #000000;
      padding: 0;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .currenLocation {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    img {
      max-width: 20px;
      margin-right: 2px;
    }

    button {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      background: none;
      border: none;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }



  .resultList {
    display: flex;
    align-items: start;

    &:last-child {
      .resultText {
        border: none;
      }
    }

    img {
      margin-right: 17px;
      max-width: 25px;
    }


  }

  .resultText {
    border-bottom: 1px solid #818181;
    margin-bottom: 15px;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    h4 {
      font-size: 16px;
      margin: 0 0 3px 0;
    }

    p {
      font-size: 16px;
      color: #080808;
      margin: 0 0 5px 0;
    }
  }

  .searchResults {
    h3 {
      color: #676767;
      font-size: 22px;
      margin-bottom: 20px;
    }
  }

  .form-select {
    height: auto !important;
  }

  .form-control {
    padding: 10px 15px;
    border-radius: 10px;
    border: 1px solid #c9c9c9;

    &:focus {
      box-shadow: none;
      border: 1px solid #c9c9c9;
    }
  }

  .changeLocation {
    background: none;
    border: none;
    font-weight: 500;
  }

  .address-type {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    @media screen and (max-width:992px) {
      gap: 20px;
    }

    // input[type='radio']:after {
    //   width: 18px;
    //   height: 18px;
    //   border-radius: 15px;
    //   top: -2px;
    //   left: -1px;
    //   position: relative;
    //   background-color: #ffffff;
    //   border: 1px solid #72727A;
    //   content: '';
    //   display: inline-block;
    //   visibility: visible;

    // }

    // input[type='radio']:checked:after {
    //   width: 18px;
    //   height: 18px;
    //   border-radius: 15px;
    //   top: -2px;
    //   left: -1px;
    //   position: relative;
    //   background-color: #16A070;
    //   content: '';
    //   display: inline-block;
    //   visibility: visible;
    //   border: 1px solid #72727A;
    // }

    input {
      margin-left: 5px;
      position: relative;
    }

    label {
      border: 1px solid #c9c9c9;
      border-radius: 5px;
      padding: 4px 11px;
      margin: 0;
    }
  }
}

.DroMapBlock {
  border: 1px solid #707070;
  border-radius: 15px;
  max-width: 100%;
  width: 100%;
  height: 405px;
  margin-bottom: 16px;
  iframe {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
}

.DropLocationModal {
  padding: 25px;

  h2 {
    font-size: 20px;
  }

  button {
    background: #ffd814;
    border-radius: 8px;
    color: #000000;
    border: 1px solid #ffd814;
    margin: 0 auto;
    display: flex;
    padding: 8px 0;
    max-width: 314px;
    font-size: 17px;
    width: 100%;
    justify-content: center;

    &:focus {
      background: #fff;
      border: 1px solid #115997;
      color: #115997;
    }

    @media screen and (max-width: 767px) {
      padding: 8px 0;
    }
  }
}

.apartmentBlock {
  border: 1px solid #000000;
  border-radius: 15px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  .MapBlock {
    height: 175.19px;

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 0px 15px 15px 0px;
    }
  }
}

.apartmentText {
  padding: 10px 15px 15px 15px;
  display: flex;
  align-items: start;
  height: 100px;
  overflow-y: auto;

  img {
    margin-right: 8px;
  }

  h4 {
    font-size: 26px;
    margin-bottom: 9px;
  }

  p {
    font-size: 16px;
    color: #000000;
  }

  button {
    box-shadow: 1px 1px 1px #0000001a;
    border: 0.5px solid #cccccc;
    color: #25e49e;
    border-radius: 30px;
    padding: 4px 17px;
    background: #fff;
  }
}

.addDropDetails {
  .error {
    color: red;
    font-size: 15px;
  }

  form {
    margin-top: 30px;

    .form-label {
      font-size: 22px;
      color: #6f6f6f;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    .form-control {
      border: 1px solid #000000;
      border-radius: 15px;
      padding: 15px;

      @media screen and (max-width: 767px) {
        padding: 12px 15px;
      }
    }
  }

  .confirmLocation {
    max-width: 314px;
    width: 100%;
    background: #000000;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    margin: 52px auto 0;
    padding: 15px;
    border: 1px solid #e2e2e2;
    color: #ffffff;
    font-size: 19px;

    &:disabled {
      border: 1px solid #f4f4f4;
      color: #6f6f6f;
      background: #f4f4f4;
    }
  }
}

.PaymentSuccess {
  text-align: center;

  h2 {
    font-weight: 500;
    margin-bottom: 13px;
  }

  h6 {
    font-size: 24px;
    font-weight: 500;
  }

  button {
    background: #29f5b2;
    border-radius: 27px;
    color: #fff;
    width: 100%;
    padding: 10px;
    font-size: 22px;
    border: 1px solid #29f5b2;
    margin-top: 20px;
  }
}

.searchBtn {
  width: 260px;
  height: 38px;
  background: #000000;
  border: 1px solid #000000;
  color: #ffffff;
  border-radius: 23px;
  font-size: 14px;
  display: block;
  margin: 14px auto 14px;
  padding: 0 20px;

  &:disabled {
    background: #f3f3f3;
    color: rgb(15, 15, 15);
    border: 1px solid #f3f3f3;

    &:hover {
      color: #000000;
      border: 1px solid #000000;
    }
  }


}

.addDropDetails {
  h2 {
    color: #300787;
  }

  .termconditionpara {
    border: 1px solid #c1baba;
    border-radius: 15px;
    padding: 15px;
    margin: 0;
  }
}