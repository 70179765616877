
  .hero-section {
    padding: 0 0 40px 0;
  }
  .hero-section .hero-flex {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  .hero-text {
    width: 50%;
  }
  .hero-text h1 {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #0F0F0F;
    padding-top: 20px;
  }
  .hero-text h1 span {
    color: #300E87;
  }
  .hero-text h6 {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: #1C2126;
    margin: 30px 0;
  }
  .hero-img {
    width: 50%;
  }
  .hero-img img {
    width: 100%;
  }
  .anchor-point {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #000;
    border: 1px solid #300E87;
    padding: 10px 20px;
    background: yellow;
    border-radius: 50px;
    margin: 40px 0 0 0;
    display: inline-block;
  }
  
  .step-section {
   margin: 60px 0;
   padding: 0;
  }
  .step-section h2 {
    text-align: center;
    font-size: 60px;
    margin: 0 0 30px 0;
 font-family: 'Roboto';
    font-weight: 700;
  }
  .step-bg {
    background: #f6f4f9;
    padding: 40px 0 40px 0;
  }
  .step-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
 font-family: 'Roboto';
  }
  .step-list li {
    padding: 20px;
    width: 20%;
  }
  .step-list li span {
    background: #300E87;
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
    color: #FEFEFE;
    padding: 3px 20px;
    border-radius: 50px;
    margin-bottom: 20px;
    display: inline-block;
  }
  .step-list li h4 {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 22px;
    line-height: 35px;
    color: #300E87;
    margin: 0 0 16px 0;
  }
  .step-list li p {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #1C2126;
  }
  
  .live-section {
    padding: 60px 20px 0 20px;
  }
  .live-section h2 {
    text-align: center;
    font-size: 60px;
    margin: 0 0 30px 0;
 font-family: 'Roboto';
    font-weight: 700;
  }
  .live-section p {
    text-align: center;
  }
  .live-section h4 {
 font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #300E87;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .live-section p {
    text-align: center;
    font-size: 20px;
    margin: 0 0 30px 0;
 font-family: 'Roboto';
    line-height: 33px;
  }
  
  .live-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .live-list li {
    background: #f6f4f9;
    border-radius: 30px;
    padding: 40px;
    width: calc(33.333% - 40px);
    margin: 20px;
  }
  .live-list li img {
    height: 67px;
  }
  .live-list li p {
    text-align: left;
  }
  .registerBtn {
    color: white;
    background-color: #300787;
    padding: 15px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    .regibtn {
      padding-right: 0;
      color: white !important;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .navbar-logo  {
    .logo {
      display: inline-block;
      vertical-align: middle;
      z-index: 2;
      img {
        max-height: 60px !important;
      }
    }
  }
  #nav-wrapper {
    width: 100%;
    z-index: 100;
    min-height: 100px;
  }
  #nav-wrapper.inited {
    max-height: initial;
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }
  
  
  .blog-section {
    padding: 60px 20px;
  }
  .blog-section h2 {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-size: 46px;
    line-height: 56px;
    color: #1C2126;
    text-align: center;
    margin: 0 0 30px 0;
  }
  .blogwrapper {
    border: 1px solid #707070;
    padding: 40px 70px;
    border-radius: 40px;
  }
  .blogwrapper h3 {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-size: 46px;
    line-height: 56px;
    color: #1C2126;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    border-bottom: 1px dashed #707070;
  }
  .blog-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .blog-list li {
    display: flex;
    align-items: center;
    width: 50%;
    padding-right: 40px;
    margin-bottom: 30px;
  }
  .blog-list li .blog-img {
    border: 1px solid #707070;
    border-radius: 10px;
    position: relative;
    padding-top: 120px;
    width: 160px;
    margin-right: 20px;
  }
  .blog-list li .blog-img img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .blog-list li .blog-details {
    width: calc(100% - 180px);
  }
  .blog-list li .blog-details h6 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1C1C1C;
    margin: 0;
    padding: 0;
  }
  
  .blog-list li .blog-details h4 {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    color: #1C2126;
    margin: 0;
    padding: 0;
  }
  
  .blog-list li .blog-details p {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #1C1C1C;
    margin: 0;
    padding: 0;
  }
  
  
  .faq-section {
    padding: 0 20px 60px 20px;
  }
  .faq-section h2 {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-size: 46px;
    line-height: 56px;
    color: #1C2126;
    text-align: center;
  }
  .faq-section h4 {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #1C2126;
  }
  .faq-section p {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;
      color: #1C2126;
  }
  
  @media (max-width: 1199px) {
    .registerBtn {
      display: inline-block;
    color: white!important;
    background-color: #300787;
    padding: 8px 20px !important;
    border-radius: 50px!important;
    z-index: 999;
    right: 20px;
    font-size: 16px;
    font-family: var(--font-headers), serif;
    font-weight: 700;

    }
  }
  
  @media (max-width: 1023px) {
    .live-list {
      flex-wrap: wrap;
    }
    .live-list li {
      width: 100%;
    }
    .live-section p {
      text-align: left;
    }
    .step-list {
      flex-wrap: wrap;
    }
    .step-list li {
      width: 50%;
  }
  .blogwrapper {
    padding: 40px 40px;
  }
  .blog-list li {
    padding-right: 0;
    width: 100%;
  }
  }
  
  @media (max-width: 767px) {

    .hero-section .hero-flex {
      padding: 0;
      flex-wrap: wrap;
    }
    .hero-text {
      width: 100%;
    }
    .hero-img {
      width: 100%;
    }
    .hero-text h1 {
      font-size: 40px;
      line-height: 51px;
  }
  .anchor-point {
    font-size: 16px;
    padding: 10px 0px;
    margin: 20px 0;
    display: inline-block;
    width: 100%;
    text-align: center;

  }
  .hero-text h6 {
    font-size: 20px;
    line-height: 30px;
    margin: 20px 0;
  }

  .step-section h2 {
    font-size: 40px;
  }
  .step-list li {
    width: 100%;
    text-align: center;
  }
  .live-section {
    padding: 0px 20px 0 20px;
  }
  .live-section h2 {
    text-align: center;
    font-size: 40px;
    margin: 0 0 30px 0;
  }
  .live-list li {
    width: 100%;
    margin: 0 0 20px 0;
    padding: 20px;
  }
  .live-section .col-sm-12 {
    padding: 0;
  }
  .blog-section {
    padding: 20px 0px;
  }
  .blog-section h2 {
    font-size: 40px;
  }
  .blogwrapper {
    padding: 20px 20px;
  }
  .blogwrapper h3 {
    font-size: 30px;
  }
  .blog-list li {
    padding-right: 0;
    width: 100%;
    flex-wrap: wrap;
  }
  .blog-list li .blog-img {
    border: 1px solid #707070;
    border-radius: 10px;
    position: relative;
    padding-top: 140px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .blog-list li .blog-details {
    width: 100%;
  }
  .faq-section h2 {
    font-size: 40px;
    line-height: 50px;
  }
  }