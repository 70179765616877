@import "./variable.scss";
@import "./fonts.scss";

// Variable
:root {
  --gray-border: #c9c9c9;
  --Dark-text: #000;
  --Light-text: #9d9d9d;
  --card-padding: 12px;
  --card-radius: 8px;
  --radio-border-width: 2px;
  --radio-size: 20px;
}

// Variable

//mixin
@mixin flex {
  display: flex;
  align-items: center;
}

//mixin
//common

body {
  font-family: Roboto;
}

.error {
  color: #ff0000;
  margin: 3px 0 0 0;
}

h1 {
  color: var(--Dark-text);
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

h6 {
  color: var(--Dark-text);
  font-size: 16px;
  font-weight: 500;
}

p {
  font-size: 14px;
  color: var(--Dark-text);
}

.btn {
  background: #f9fbfc;
  border: 1px solid #00000000;
  border-radius: 8px;
  padding: 9px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #656565;
  line-height: normal;
  min-height: 35px;

  &:focus {
    box-shadow: none;
  }

  &:active {
    color: #115997 !important;
    border-color: #115997 !important;
  }

  &.Dark-btn {
    border-radius: 4px;
    background: #40B05F;
    color: #fff;
    border: 1px solid #40B05F;
  }

  &.btn-outline {
    border-radius: 4px;
    border: 1px solid #9AC9F1;
    color: #444448;
    background: #fff;
  }
}

.Icon {
  max-width: 15px;
}

.notfounderror {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
}

.profile-editor {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 6px 0;
    border-bottom: 1px solid var(--gray-border);
    cursor: pointer;

    &:last-child {
      border: none;
    }

    a {
      text-decoration: none;
      font-size: 14px;
      color: var(--Dark-text);
      white-space: nowrap;
    }
  }
}

.categoriesMobile {
  display: none;

  @media screen and (max-width: 992px) {
    display: block;
    padding: 20px 0 0 0;
  }

  .categoriesFilter {
    display: flex;
    gap: 15px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    p {
      margin: 0;
      font-size: 14px;
      white-space: nowrap;
    }
  }
}

.CategoriesSection {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-top: 40px;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
    gap: unset;
    padding-top: 10px;
  }

  h4 {
    color: var(--Dark-text);
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }

  h3 {
    color: var(--Dark-text);
    font-size: 22px;
    font-weight: 500;
  }

  .FilterSection {
    width: 350px;
    height: calc(100vh - 90px);
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
    border: 1px solid var(--gray-border);
    border-radius: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 1536px) {
      width: 300px;
    }

    @media screen and (max-width: 992px) {
      display: none;
    }

    .CategoriesListSection {
      display: inline-block;
      width: 100%;
      height: 100%;

      .categories-list {
        font-weight: 500;
        color: var(--Dark-text);
        font-size: 18px;
        padding: 10px 15px;
        justify-content: space-between;
        background: rgb(255, 255, 255) !important;
        // padding: 10px 15px;
        @include flex;
      }

      .categories-list.active {
        background: #eae6f3 !important;
      }

      .sub-categories-list.active {
        border-left: 4px solid var(--Dark-text) !important;
        padding-left: 10px !important;
      }

      .sub-categories-list {
        color: var(--Dark-text);
        font-size: 15px;
        padding: 5px 0 5px 15px;
        border-left: 4px solid #3498db00;
        margin-left: 5px;

        &:hover {
          border-left: 4px solid var(--Dark-text);
        }
      }

      .sub-category-list-child {
        list-style: none;

        .active {
          border-left: 4px solid var(--Dark-text) !important;
          padding-left: 10px !important;
        }

        li {
          padding: 5px 0;

          &:hover {
            border-left: 4px solid var(--Dark-text) !important;
            padding-left: 10px !important;
          }

          button {
            background: none;
            border: none;
            padding: 0;
            text-align: left;
          }

          a {
            text-decoration: none;
            font-size: 16px;
            color: var(--Dark-text);
          }
        }
      }

      .Icon {
        max-width: 30px;
      }

      a[aria-expanded="true"] {
        background-color: #42dca3;
      }
    }
  }

}

.read-or-hide {
  font-weight: 500;
  color: #44464B
}
.size {
  @include flex;
  flex-wrap: wrap;
  gap: 10px;

  .active {
    background: var(--Dark-text);
    color: #fff;
  }

  span {
    background: #f9fbfc;
    padding: 8px;
    width: 30px;
    height: 30px;
    @include flex;
    justify-content: center;
    border: 1px solid #f1f1f1;
    font-size: 10px;
    border-radius: 5px;
  }
}

.filterLabel {
  font-size: 14px;
  font-weight: 400;
  color: var(--Dark-text);
}

.colorFilter {
  $colors: (
    red: #db2828,
    yellow: #000000,
    green: #878787,
  );

input[type="radio"] {
  display: none;

  &:checked+label {
    span {
      transform: scale(1.25);
    }

    @each $name,
    $value in $colors {
      .#{$name} {
        border: 2px solid darken($value, 25%);
      }
    }
  }
}

label {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 0;

  &:hover {
    span {
      transform: scale(1.25);
    }
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in-out;

    @each $name,
    $value in $colors {
      &.#{$name} {
        background: $value;
      }
    }
  }
}
}

// .addtocart-qty {
//   display: flex;
//   align-items: flex-start;
//   width: 100%;
//   max-width: 110px;
//   box-shadow: 0px 2.72165px 5.4433px 0px rgba(0, 0, 0, 0.15);
//   border-radius: 5.443px;
//   background: #40B05F;
//   border: 1px solid #40B05F;
//   height: 32px;
//   position: absolute;
//   top: 0;
//   right: 0;

//   .addtocart-button,
//   .addtocart-input {
//     text-align: center;
//     width: 25%;
//     height: 30px;
//     padding: 0;
//     font-size: 21px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100%;
//     color: #115997;
//   }

//   .minus {
//     font-size: 35px;
//     color: #115997;
//   }

//   .addtocart-button {
//     text-align: center;
//     cursor: pointer;
//     background-color: #ffffff;
//     transition: all 0.25s;
//     width: 100%;

//     &.button-down {
//       border-radius: 5px 0px 0 5px;
//     }

//     &.button-up {
//       border-radius: 0 5px 5px 0;
//       color: #ffffff;
//     }
//   }

//   .addtocart-input {
//     border: 1px solid #c6c6c600;
//     border-left: none;
//     border-right: none;
//     font-weight: bold;
//     font-size: 15px;
//     width: 100%;
//     height: 100%;

//     &:focus-visible {
//       outline: none;
//     }
//   }
// }

.addtocart-selector {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;

  button {
    flex: 1 0 auto;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    min-width: 140px;
    border: #ffd814;
    background-color: #ffd814;
    color: #000000;
    transition: background-color 0.25s;
  }

  .addtocart-qty {
    position: unset;
  }
}

.product-text {
  padding-top: 15px;

  p {
    word-break: break-all;
    font-size: 14px;
  }

  .productDesc-Details {
    max-width: 450px;
    width: 100%;

    tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 0;

      td {
        font-size: 14px;
        color: var(--Dark-text);
      }
    }
  }
}

.product-Details {
  padding-left: 30px;

  @media screen and (max-width: 992px) {
    padding: 0;
  }
}

.addAddress {
  color: #59399f;
  border: none;
  border-radius: var(--card-radius);
  width: 100%;
  padding: 15px 0;
  // margin-bottom: 15px;
  text-align: left;
  background-color: #fff;
}

.cartView-address-wrapper {
  @media screen and (max-width:992px) {
    margin-bottom: 20px;
  }
}

.cardgrid {
  display: grid;
  grid-gap: 1em;
  padding: 0;
  max-height: calc(100vh - 170px);
  overflow-y: auto;

  @media (min-width: 42em) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  display: flex;
  flex-direction: row;
  border-radius: 7px;

  label {
    padding: var(--card-padding);
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
    gap: 8px;
  }

  input {
    height: 13px;
    margin-top: 5px;
    position: relative;
  }

  // .three-dots {
  //   height: 20px;
  //   position: relative;
  //   top: 15px;
  //   right: 5px;
  //   cursor: pointer;
  // }
}

input[type="radio"]:checked:after {
  width: 13px;
  height: 13px;
  border-radius: 15px;
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: #16a070;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #72727a;
}

.plan-details {
  border-radius: var(--card-radius);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;

  .plan-type {
    margin-bottom: 5px;

    span {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .plan-cost {
    margin-bottom: 5px;
  }
}

.cartView-price {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(96, 97, 97, 0.15);
  width: 100%;

  .checkoutCards {
    max-height: 360px;
    overflow-y: auto;
    padding: 9px;
  }

  .step-completed {
    background-color: rgb(98 182 254 / 20%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 15px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .type_label {
      margin-bottom: 2px;
      font-size: 12px;
    }

    .image-preview {
      max-width: 400px;
      height: 120px;

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }
}

.cartView-header {
  border-bottom: 1px solid rgb(240, 240, 240);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 7px 15px;

}

.cartView-body {
  .productCard {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    padding: 12px;
    width: 100%;
    position: relative;

    .product_img {
      align-items: center;
      border-radius: 8px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
      // display: flex;
      flex-basis: 30%;
      height: 110px;
      // justify-content: center;
      padding: 5px;
      width: 110px;

      img {
        border-radius: 5px;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.add_address {
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  padding: 7px 15px;
  width: 160px;
  font-weight: 500;
  background: #ffd814;
  color: #000000;
  border: 1px solid #ffd814;
  min-height: 35px;

  &:focus-visible {
    background: #fff;
    color: #115997;
    border: 1px solid #115997;
  }

  &:hover {
    background: #ffd814;
    border: 1px solid #ffd814;
    transition: all 0.3s;
    color: #000000;
  }
}

.form-group {
  margin: 0;

  input[type="radio"] {
    display: none;
  }

  label {
    cursor: pointer;
    position: relative;
    font-size: 16px;
    color: var(--Dark-text);
    margin: 0;
    padding-left: 35px;
  }

  label::before {
    content: "";
    position: absolute;
    width: var(--radio-size);
    height: var(--radio-size);
    background-color: transparent;
    border: 2px solid #72727a;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: border-color 400ms ease;
  }

  label::after {
    content: "";
    position: absolute;
    width: 38px;
    height: 38px;
    background-color: #3384ff;
    border: 2px solid #3384ff;
    border-radius: 50%;
    top: 50%;
    left: -7px;
    transform: translateY(-50%) scale(0);
    transition: transform 400ms ease;
  }

  input[type="radio"]:checked+label::before {
    border-color: #101011;
  }

  input[type="radio"]:checked+label::after {
    transform: translateY(-50%) scale(0.55);
  }
}

.cartView-card-footer {
  padding: 2px 15px;
}

.cartView_provider {
  max-height: 300px;
  overflow-y: auto;
}

.cartView-card-body {
  margin: 0;
  text-align: left;
}

.cartView-sub-total {
  margin: 0;
  font-weight: 600;
}

.cartView-product-name {
  font-weight: 500;
  margin: 0;
  width: 70%;
  word-break: break-all;
}

.cartView_ordered {
  margin: 0;
}

.border-bottom-dotted {
  border-bottom: 1px dotted #aaa;
  padding: 8px 15px;
}

.editdots {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-size: 16px;
    cursor: pointer;
  }
}

.notdatafound {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-found-data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 97%;
  transform: translate(-50%, -50%);
}

.button-loader {
  position: relative;

  .lds-ellipsis div {
    background: #fff;
  }
}

.dotLoader {
  .lds-ellipsis {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

.loginloder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}

.lds-ellipsis div {
  position: absolute;
  width: 13px;
  align-self: center;
  height: 13px;
  border-radius: 50%;
  top: 4px;
  background: #115997;
  align-self: center;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}


.productSection {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: calc(100vh - 260px);

  h5 {
    font-size: 20px;

    @media screen and (max-width:767px) {
      font-size: 18px;
    }
  }

  h4 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  h3 {
    color: #000;
    font-size: 20px;
    font-weight: 500;

    @media screen and (max-width:992px) {
      font-size: 18px;
    }
  }

  .product-slider {
    .slick-slide {
      >div {
        width: 100%;
      }
    }
  }

  .product-slider-as-nav {
    .slick-slide {
      >div {
        padding: 0 5px;
      }
    }

    .slick-prev {
      left: 0;
      z-index: 9;
    }

    .slick-next {
      right: 0;
    }

    .slick-prev:before,
    .slick-next:before {
      color: var(--Dark-text);
    }
  }

  .prodcutImage {
    height: 300px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .prodcutNavImage {
    height: 100px;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 5px;
      padding: 0;
      transition: 0.3s ease-in-out;
    }

    img {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .priceDetails {
    border: 1px solid var(--gray-border);
    border-radius: 8px;

    table {
      tbody {
        tr {
          border-top: 1px solid var(--gray-border);
        }
      }

      th,
      td {
        padding: 8px 15px;
        font-size: 14px;
      }
    }
  }

  .no-items {
    text-align: center;
    height: calc(100vh - 140px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-width: 60px;
    }

    h6 {
      margin: 20px 0;
    }
  }

  .editText {
    margin: 0;
    font-size: 16px;
    color: #005298;
    font-weight: 500;
  }

  .buyerDetails {
    .form-label {
      font-size: 12px;
      color: #72727A;
      font-weight: 400;
    }

    .form-control {
      font-size: 14px;
      background: #F6F6F7;
      border: 1px solid #C0C0C7;
      border-radius: 4px;
      padding: 10px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}
.product--Section {
  // max-width: 900px;
  // width: 100%;
  // margin: 0 auto;
  // position: relative;
  // min-height: calc(100vh - 260px);

  h5 {
    font-size: 20px;

    @media screen and (max-width:767px) {
      font-size: 18px;
    }
  }

  h4 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  h3 {
    color: #000;
    font-size: 20px;
    font-weight: 500;

    @media screen and (max-width:992px) {
      font-size: 18px;
    }
  }

  .product-slider {
    .slick-slide {
      >div {
        width: 100%;
      }
    }
  }

  .product-slider-as-nav {
    .slick-slide {
      >div {
        padding: 0 5px;
      }
    }

    .slick-prev {
      left: 0;
      z-index: 9;
    }

    .slick-next {
      right: 0;
    }

    .slick-prev:before,
    .slick-next:before {
      color: var(--Dark-text);
    }
  }

  .prodcutImage {
    height: 300px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .prodcutNavImage {
    height: 100px;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 5px;
      padding: 0;
      transition: 0.3s ease-in-out;
    }

    img {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .priceDetails {
    border: 1px solid var(--gray-border);
    border-radius: 8px;

    table {
      tbody {
        tr {
          border-top: 1px solid var(--gray-border);
        }
      }

      th,
      td {
        padding: 8px 15px;
        font-size: 14px;
      }
    }
  }

  .no-items {
    text-align: center;
    height: calc(100vh - 140px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-width: 60px;
    }

    h6 {
      margin: 20px 0;
    }
  }

  .editText {
    margin: 0;
    font-size: 16px;
    color: #005298;
    font-weight: 500;
  }

  .buyerDetails {
    .form-label {
      font-size: 12px;
      color: #72727A;
      font-weight: 400;
    }

    .form-control {
      font-size: 14px;
      background: #F6F6F7;
      border: 1px solid #C0C0C7;
      border-radius: 4px;
      padding: 10px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}
.collectionFilter {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: start;
@media screen and (max-width:767px){
  margin-top: 15px;
  justify-content: space-between;
}

  .filterItem {
    border-radius: 5.443px;
    border: 0.5px solid #003A6C;
    cursor: pointer;
    position: relative;
    min-width: 180px;

    @media screen and (max-width:767px) {
      min-width: 160px;
    }

    &:last-child {
      min-width: 150px;
    }

    label {
      margin: 0;
      font-size: 14px;
    }

    select {
      border: none;
      font-size: 14px;
      appearance: none;
      cursor: pointer;
      width: 100%;
      z-index: 0;
      position: relative;
      font-weight: 400;
      border-radius: 5.443px;
      padding: 8px 10px;
      --bs-form-select-bg-img: none;

      @media screen and (max-width:767px) {
        font-size: 12px;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .downArrow {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      max-width: 18px;
    }
  }
}

.ProductList-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .productCard {
    border-radius: 8px;
    border: 1px solid #E7E8EA;
    gap: 40px;
    padding: 12px 20px;
    position: relative;

    @media screen and (max-width:992px) {
      padding: 12px;
    }

    .productimage {
      border: 1px solid #E7E8EA;
      border-radius: 10px;
      max-width: 132px;
      width: 100%;
      height: 117px;
      margin-bottom: 16px;
      position: relative;

      .productImg {
        max-width: 100%;
        height: 100%;
        width: 100%;
        border-radius: 10px;

        img {
          max-width: 100%;
          height: 100%;
          width: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .add--cart-btn {
        position: absolute;
        bottom: -16px;
        max-width: 97px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
      }


    }
  }

}

.categoryTitle {
  @media screen and (max-width:767px) {
    font-size: 20px;
  }
}

.addtocart-qty {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 110px;
  box-shadow: 0px 2.72165px 5.4433px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5.443px;
  background: #40B05F;
  border: 1px solid #40B05F;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 36px;


  .addtocart-button,
  .addtocart-input {
    text-align: center;
    width: 25%;
    height: 30px;
    padding: 0;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #ffffff;
    background: #40B05F;
    padding: 3px;
    min-height: 36px;
  }

  .minus {
    font-size: 35px;
    color: #115997;
  }

  .addtocart-button {
    text-align: center;
    cursor: pointer;
    color: #fff;
    background: #40B05F;
    transition: all 0.25s;
    width: 100%;
    min-height: 36px;

    &.button-down {
      border-radius: 5.443px 0px 0 5.443px;
    }

    &.button-up {
      border-radius: 0 5px 5px 0;
      color: #ffffff;
    }
  }

  .addtocart-input {
    border: 1px solid #c6c6c600;
    border-left: none;
    border-right: none;
    font-weight: bold;
    font-size: 15px;
    width: 100%;
    height: 100%;

    &:focus-visible {
      outline: none;
    }
  }
}

.addBtn {
  background: #FFF;
  border: 1px solid #fff;
  box-shadow: 0px 2.72165px 5.4433px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5.443px;
  color: #40B05F;
  padding: 3px;
  max-width: 100px;
  width: 100%;
  font-size: 19px;
  font-weight: 600;
}

.productDetail {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;

  @media screen and (max-width:992px) {
    gap: 0;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .productRate {
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: end;

    @media screen and (max-width:992px) {
      margin-bottom: 10px;
      flex-direction: row-reverse;
    }
  }

  h1 {
    font-size: 20px;
    font-weight: 600;
    color: #005298;
  }

  .productTitle {
    font-size: 18px;
    font-weight: 500;
    color: #3C424E;

    @media screen and (max-width:767px) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  .productDes {
    @media screen and (max-width:992px) {
      margin-bottom: 10px;
    }
    .text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: #44464B;
      margin: 0;
      word-break: break-all;
    }
  }
}

.detail-page {
  border-radius: 5px;
  border: 1px solid #E7E8EA;
  background: #fff;
  color: #5E6470;
  padding: 5px 20px 5px 10px;
  text-decoration: none;
  margin: 0;
  position: relative;
  font-size: 14px;

  @media screen and (min-width:992px) {
    display: none;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    background: url(../images/rightarrow.svg) no-repeat;
    width: 18px;
    height: 22px;
    background-position-y: center;
    background-size: 18px;

  }
}

.line-thorugh {
  text-decoration: line-through;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #79767D;
}

.removebtn {
  background: none;
  border: none;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  color: #3C424E;
  position: absolute;
  bottom: 10px;
}

.cart-provider {
  max-height: calc(100vh - 135px);
  overflow-y: auto;

  @media screen and (max-width:992px) {
    margin-bottom: 20px;
  }
}

// .....................header-start
.headerRight {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;

  @media screen and (max-width:992px) {
    justify-content: space-between;
  }

  .popover__wrapper {
    position: relative;
    display: inline-block;
    min-width: 140px;

    @media screen and (max-width:767px) {
      min-width: 64px;
    }
  }

  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    transform: translate(0, 10px);
    background-color: #ffffff;
    padding: 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    top: 72px;
    z-index: 9;

    @media screen and (max-width:768px) {
      left: 0;
    }
  }

  .popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    transition-duration: 0.3s;
    transition-property: transform;

    @media screen and (max-width:768px) {
      right: auto;
    }
  }

  .popover__wrapper:hover .popover__content {
    z-index: 999;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }

  .popover__message {
    text-align: center;
  }

  .shopName {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 155px;
    width: 100%;
    text-align: center;

    @media screen and (max-width:992px) {
      font-weight: 500;
    }
  }

  .profileImg {
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width:992px) {
      color: #000;
      font-weight: 400;
    }
  }

 
}
 .profileDetails {
    position: relative;

    a {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;

      .Icon {
        max-width: 28px;
        margin: 0 auto;
        display: block;

        @media screen and (max-width:992px) {
          margin: 0;
        }
      }
    }

    .cartCounter {
      width: 15px;
      height: 15px;
      background: #ffffff;
      color: #000000;
      font-weight: 500;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      position: absolute;
      top: -1px;
      right: -4px;


    }
  }
.headerSection {
  background: #115997;
  padding: 4px 0;
  display: block;
  position: sticky;
  top: 0;
  z-index: 1;

  .headermain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 15px;

    .contactDetail {
      display: flex;
      gap: 15px;
      align-items: center;

      p {
        cursor: pointer;

        .homeicon {
          height: 20px;
          max-width: 20px;
          display: block;
        }
      }

      .contactNo {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        position: relative;
        padding-left: 25px;
        text-decoration: none;
        margin: 0;

        &::before {
          content: "";
          position: absolute;
          background: url(../images/PhoneCall.svg) no-repeat;
          width: 22px;
          height: 22px;
          background-size: 22px;
          left: 0;
        }
      }

      .WhatsaapNo {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        position: relative;
        padding-left: 25px;
        text-decoration: none;
        margin: 0;

        &::before {
          content: "";
          position: absolute;
          background: url(../images/WhatsappLogo.svg) no-repeat;
          width: 22px;
          height: 22px;
          background-size: 22px;
          left: 0;
        }
      }
    }
  }
}

// ......shopdetails

.shopDetails {
  padding: 15px 0;
  box-shadow: 0 8px 5px -6px rgba(204, 204, 204, 0.8196078431);
  display: none;
  background: #ffd814;

  @media screen and (min-width:992px) {
    display: block;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    margin: 0;
  }

  .shopDetailFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .shopImg {
      max-width: 140px;
      height: 80px;
      width: 100%;

      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .shopTitlt {
      max-width: 600px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      max-width: 700px;
      width: 100%;

      @media screen and (max-width:1400px) {
        max-width: 470px;
      }

      .title {
        font-size: 20px;
        font-weight: 600;
        color: #000;
        margin: 0 0 7px 0;

        @media screen and (max-width:1400px) {
          font-size: 18px;
        }
      }

      .text {
        max-height: 55px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

  

    .shopAddress {
      max-width: 228px;

      .address {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        position: relative;
        padding-left: 27px;

        &::before {
          content: "";
          position: absolute;
          background: url(../images/location.svg) no-repeat;
          width: 18px;
          height: 18px;
          background-size: 18px;
          left: 0;
        }
      }
    }
  }
}
.read-or-hide {
  font-weight: 600;
  cursor: pointer;

}
.search-top {
  max-width: 605px;
  width: 100%;
}

.searchBar {
  border-radius: 5.443px;
  border: 0.5px solid #003A6C;
  background: #F6F6F6;
  max-width: 100%;
  width: 100%;
  position: relative;
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  // display: flex;
  // z-index: 99;

  // @media screen and (max-width:992px) {
  //   border: 1px solid #B5B7BD;
  //   position: relative;
  //   transform: none;
  //   margin: 0 auto;
  //   left: 0;
  //   right: 0;
  //   max-width: 100%;
  //   z-index: 0;
  // }

  .searchInput {
    width: 100%;
    display: flex;
    padding: 0 8px;
  }

  .searchDropdown {
    position: absolute;
    top: 31px;
    background: #f6f6f6;
    width: 100%;
    left: 0;
    padding: 15px;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 0 0 5.443px 5.443px;
    z-index: 99;
    overflow-y: auto;
    max-height: 370px;
    max-width: 100%;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #003A6C ;
    border-top: 0;

    @media screen and (max-width:992px) {
      border-top: none;
      width: 100%;

    }

    p {
      border-bottom: 1px solid #e1e1e1;
      margin: 0;
      padding: 9px 0;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  button {
    background: none;
    border: none;
  }

  input {
    border: none;
    padding: 0;
    width: 100%;
    padding: 7px 0;
    background: none;

    &:focus {
      outline: none;

    }
  }
}

.mobileSearch {
  display: none;

  @media screen and (max-width:992px) {
    display: block;
    // position: sticky;
    // top: 68px;
    background: #fff;
    // z-index: 9;
    padding: 15px 0;
  }
}

.MobileDetails {
  display: block;

  @media screen and (min-width:992px) {
    display: none;
  }

  .shopAbout {
    background: #18306E;
    border-radius: 8px;
    display: flex;
    margin-bottom: 12px;

    .shopImg {
      width: 50%;
      height: 110px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px 0 0 8px;
      }
    }

    .shopContent {
      width: 50%;
      padding: 10px;

      h6 {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
      }

      p {
        margin: 0;
        font-size: 13px;
        font-weight: 400;
        color: #fff;
        max-height: 60px;
        overflow-y: auto;
      }
    }
  }

  .shopaddress {
    .mobileViewaddress {
      font-size: 12px;
      color: #3F4555;
      font-weight: 400;
      position: relative;
      padding-left: 27px;
      margin: 0;

      &::before {
        content: "";
        position: absolute;
        background: url(../images/location.svg);
        background-size: 20px;
        height: 20px;
        width: 20px;
        left: 0;
      }
    }
  }

  .contactNumber {
    font-size: 12px;
    font-weight: 400;
    color: #3F4555;
    margin: 0;
    position: relative;
    padding-left: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:first-child {
      &::before {
        content: "";
        position: absolute;
        background: url(../images/contact.svg) no-repeat;
        width: 25px;
        height: 25px;
        background-size: 25px;
        left: 0;
      }
    }
  }

  .WhatsaapNumber {
    font-size: 12px;
    font-weight: 400;
    color: #3F4555;
    margin: 0;
    position: relative;
    padding-left: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      background: url(../images/whatsaap.svg) no-repeat;
      width: 25px;
      height: 25px;
      background-size: 25px;
      left: 0;
    }
  }
}

// .........header close

// .......mobile header
.mobileHeader {
  padding: 8px 0;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  display: none;
  background: #ffd814;

  @media screen and (max-width:992px) {
    display: block;
  }


}

// .......mobile header close
.orderSection {

  table {
    text-align: center;

    tr {
      th {
        background: #f3f6f9;
        border-bottom: none;
        color: #878a99;
        font-weight: 400;
        white-space: nowrap;
      }

      th,
      td {
        font-size: 14px;
        white-space: nowrap;
      }

      .badge-soft-secondary {
        color: #5b71b9;
        background-color: rgba(91, 113, 185, .1);
        font-size: 12px;
        padding: 3px 10px;
        border-radius: 25px;
      }

      .badge-soft-danger {
        color: #f17171;
        background-color: rgba(241, 113, 113, .1);
        font-size: 12px;
        padding: 3px 10px;
        border-radius: 25px;
      }
    }
  }
}

.orderDetails {
  .card {
    flex-direction: column;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px rgba(56, 65, 74, .15);

    thead {
      background: #f3f6f9;

    }

    .detailflex {
      display: flex;

      @media screen and (max-width:767px) {
        flex-wrap: wrap;
        gap: 10px;
      }

      .product--details {
        margin-left: 15px;

        @media screen and (max-width:767px) {
          margin: 0;
        }
      }
    }

    .table th {
      font-weight: 400;
      font-size: 14px;
      white-space: nowrap;
      border: none;
      padding: 12px;

      @media screen and (max-width:767px) {
        padding: 9px;
      }
    }

    .table td {
      font-weight: 400;
      font-size: 14px;
      padding: 12px;

      @media screen and (max-width:767px) {
        padding: 9px;
      }
    }

    .card-header {
      background: #fff;
      padding: 16px;
      border-radius: 7px 7px 0 0;

      .card-title {
        font-size: 16px;
        margin: 0 0 7px 0;
        font-weight: 400;
      }

      .card-title.address {
        position: relative;
        padding-left: 20px;

        &::before {
          position: absolute;
          content: "";
          background: url(../images/location.svg) no-repeat;
          width: 15px;
          height: 15px;
          background-size: 15px;
          left: 0;
        }
      }
    }

    .card-body {
      .table-card {
        border-radius: 0 0 7px 7px;
      }
    }

    .product-title {
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  .border-top-dashed {
    border-top-style: dashed !important;
  }

  .total-amount {
    border-radius: 0 0 7px 7px;

    td {
      border: none;
    }
  }
}

.list-unstyled {
  display: flex;
  flex-direction: column;
  gap: 10px;

  li {
    font-size: 14px;

    &:first-child {
      font-size: 15px;
      font-weight: 500;
    }
  }

  // .profileImg {
  //   width: 48px;
  //   height: 48px;
  //   border-radius: 5px;

  //   img {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //     border-radius: 5px;
  //   }
  // }

  .contact-details {
    p {
      position: relative;
      padding-left: 25px;

      &::before {
        position: absolute;
        content: "";
        background: url(../images/phone-call.svg) no-repeat;
        width: 15px;
        height: 15px;
        background-size: 15px;
        left: 0;
      }
    }

    &:last-child {
      p {
        &::before {
          background: url(../images/email.svg) no-repeat;
          background-size: 15px;
          top: 3px;
        }
      }
    }
  }
}

.Payment-Details {
  h6 {
    font-size: 14px;
  }
}

.profile-timeline {
  .accordion-item {
    position: relative;
    border: none;

    &::before {
      content: "";
      border-left: 2px dashed #dcdcdc;
      position: absolute;
      height: 100%;
      left: 23px;
    }

    &:first-child {
      &::before {
        top: 8px;
      }
    }

    .accordion-button {
      background-color: transparent;
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }

    }

    .avatar-title {
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 15px;
        height: 15px;
      }
    }

    h6 {
      font-size: 14px;
    }

    .item-details {
      font-size: 12px;
    }
  }

  .lastitem {
    &::before {
      border: none;
    }
  }

  .accordion-button:not(.collapsed)::after {
    display: none;
  }

  .accordion-button::after {
    display: none;
  }
}
.view--detail-modal .modal-dialog{
  max-width: 100%;
  margin: 0;
}
.productDetailModal {
  .modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, 50px);
  }

  .modal-dialog {
    position: fixed !important;
    bottom: 0 !important;
    left: 0% !important;
    right: 0% !important;
    margin-bottom: 0 !important;

  }

  .modal.show .modal-dialog {
    transform: none;
  }

  .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

  }
}

.productImgOrderDetails {
  width: 80px;
  height: 80px;
  margin: 0 auto 8px;

  img {
    height: 100%;
    width: 100%;
  }
}

.threedotsDropdown {
  position: relative;

  .threedots {
    position: relative;

    .three-dots {
      height: 20px;
      position: relative;
      top: 15px;
      right: 5px;
      cursor: pointer;
    }
  }

  .dropdown-menu {
    --bs-dropdown-min-width: 5rem !important;

    .dropdown-item {
      cursor: pointer;
    }
  }

  .dropdown-menu.show {
    top: 15px !important;
    left: -5px !important;

  }

  .dropdown-item:active {
    background-color: none;
  }
}

.modal-content {
  border: none;
}

.view-all {
  box-shadow: rgb(149 157 165 / 18%) 0px 8px 24px;
  padding: 4px 12px;
  border-radius: 5px;
  border: 1px solid #ffd814;
  background-color: #ffd814;
  font-size: 13px;
}


// ....footer

.page-footer {
  background-color: #131313;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(//kiko.link/wp-content/uploads/2018/02/footer.png) !important;
  padding: 25px 0 10px 0;
}

.footer-widget-area {
  padding: 30px 0 20px 0;

  @media screen and (max-width:992px) {
    text-align: center;
  }
}

.widget.widget_text .kiko-logo img {
  max-width: 91px;
  width: 100%;
  margin: 0 0 30px 0;
}

.footer-widget-area .widget.widget_text {
  margin-bottom: 20px;
}

.footer-widget-area .textwidget:nt {
  color: #ffffffbf;
}

.footer-widget-area .textwidget p,
p>a {
  line-height: 1.6em;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}

.footer-widget-area .textwidget p,
p>a:hover {
  color: #fff;
  text-decoration: none;
}

.textwidget .socialIcon img {
  max-width: 20px;
}

.footer-widget-area .textwidget ul {
  padding: 0;
  margin: 0;
}

.footer-widget-area .textwidget ul li {
  display: inline-block;
  margin: 0 20px 0 0;
}

.menu-footer-container .menu {
  column-count: 2;
  list-style: none;
  padding: 0;

  @media screen and (max-width:992px) {
    column-count: 1;
  }
}

.menu-footer-container .menu .menu-link {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
  color: #fff;
  text-decoration: none;
}

.menu-footer-container .menu .menu-link:hover {
  color: #a993db;
}

.menu-footer-container .menu .menu-item {
  padding: 12px 0;
}

.copyright-block {
  position: relative;
  background-color: #131313;
  padding: 22px 0;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.copyright-block p {
  color: #fff;
  text-align: left;
  font-size: 14px;
  margin: 0;
}

.downloadAppButtons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
  margin: 50px 0;

  @media screen and (min-width:768px) {
    max-width: 300px;
  }
}

.AppButtons {
  width: 50%;
  border: 0.5px solid #797979;
  border-radius: 7px;
}

.AppButtons .downloadLink {
  display: block;
}

.AppButtons img {
  width: 100%;
  height: 100%;
}

.prepaid-checkbox {
  height: 13px;
  position: relative;
}

.header--menu {
  max-width: 25px;
}