.side-hamburger.offcanvas.offcanvas-start {
  z-index: 9999;

  @media screen and (max-width: 992px) {
    max-width: 250px;
  }
}

.header-img {
  width: 100%;
  height: 246px;
  position: relative;

  @media screen and (max-width: 992px) {
    height: 136px;
  }

  .header__icons {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;

    @media screen and (max-width: 992px) {
      padding: 20px;
    }

    svg {
      width: 48px;
      height: 48px;
      color: #000;
    }
  }

  .Profile--text {
    position: absolute;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
    background: #fff;
    width: 104px;
    height: 104px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -50px;

    @media screen and (max-width: 992px) {
      width: 72px;
      height: 72px;
      bottom: -32px;
    }

    .profile-word {
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      color: 322F35;

      @media screen and (max-width: 992px) {
        font-size: 32px;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.menu-title h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 9px;
  border-bottom: 1px solid #c4c4ca;
  color: #3f4555;
}

.menu-title h1 img {
  max-width: 32px;
}

.menu--list {
  list-style: none;
  padding: 0;

  li {
    position: relative;
    padding: 8px 0 8px 35px;
    border-bottom: 1px solid #e1e0e0;

    &:first-child {
      &::before {
        content: "";
        position: absolute;
        background: url(../../components/NewImages/User.svg) no-repeat;
        background-size: 25px;
        width: 25px;
        height: 24px;
        left: 0;
      }
    }

    &:nth-child(2) {
      &::before {
        content: "";
        position: absolute;
        background: url(../../components/NewImages/Crosshair.svg) no-repeat;
        background-size: 25px;
        width: 25px;
        height: 24px;
        left: 0;
      }
    }

    &:last-child {
      border: none;

      &::before {
        content: "";
        position: absolute;
        background: url(../../components/NewImages/Cube.svg) no-repeat;
        background-size: 25px;
        width: 25px;
        height: 24px;
        left: 0;
      }
    }

    .Menu--item {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      color: #3f4555;
    }
  }
}

.logout_menu {
  margin-top: 150px;

  .menu-logout-list {
    position: relative;
    padding: 8px 0 8px 35px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    color: #3f4555;

    &:hover {
      text-decoration: none;
      color: #3f4555;
    }

    &::before {
      content: "";
      position: absolute;
      background: url(../../components/NewImages/SignOut.svg) no-repeat;
      background-size: 25px;
      width: 25px;
      height: 24px;
      left: 0;
    }
  }
}

.shop__details {
  padding: 70px 0 24px 0;

  @media screen and (max-width: 992px) {
    padding: 50px 0 20px 0;
  }

  .shop-left {
    @media screen and (max-width: 992px) {
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 8px;
    }

    .shop-name {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      color: #005298;
      margin-bottom: 5px;

      @media screen and (max-width: 992px) {
        font-size: 20px;
        text-align: center;
      }
    }

    .shop-about {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: #005298;
      margin: 0 0 10px 0;
    }
  }

  .shop-right {
    .shop--address {
      position: relative;
      padding-left: 35px;
      font-size: 12px;
      font-style: normal;
      color: #322f35;
      font-weight: 400;
      min-height: 30px;

      &::before {
        position: absolute;
        content: "";
        background: url(../../components/NewImages/Storefront.svg) no-repeat;
        left: 0;
        width: 25px;
        height: 25px;
        background-size: 25px;
      }
    }

    .contact--icon {
      position: relative;
      cursor: pointer;
      color: #2e3138;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      padding-left: 32px;
    }

    .contactNo {
      &::before {
        content: "";
        position: absolute;
        background: url(../../components/NewImages/phone-icon.svg) no-repeat;
        background-size: 25px;
        width: 25px;
        height: 25px;
        left: 0;
      }
    }

    .WhatsaapNo {
      &::before {
        content: "";
        position: absolute;
        background: url(../../components/NewImages/whatsaap-icon.svg) no-repeat;
        background-size: 25px;
        width: 25px;
        height: 25px;
        left: 0;
      }
    }
  }
}

.LoginPopup {
  text-align: center;
  padding: 22px 18px;

  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #27272b;
  }

  .login-para {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #27272b;
  }
}

.term-condition {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #72727a;
  margin-top: 5px;

  a {
    color: #005298;
    text-decoration: underline;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
}

.loginloder .lds-ellipsis div {
  background: #fff;
}

.right--icon a {
  svg {
    color: #000;
  }
}

.number--min {
  min-height: 30px;
}
.header-image-block .slick-next:before {
  display: none !important;
}
.header-image-block .slick-next {
  display: none !important;
}
.cart-Counter {
   width: 23px;
   height: 23px;
   background: #000000;
   color: #ffffff;
   font-weight: 500;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 13px;
   position: absolute;
   top: -7px;
   right: -9px;
}