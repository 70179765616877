.WelcomeCard {
  background: #ffffff;
  padding: 30px;
  text-align: center;
  margin: 0 auto;

  p {
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0px;
    color: #121212;
    text-transform: uppercase;
    font-family: 'Roboto';
  }

  .SecondaryBtn {
    width: 260px;
    height: 38px;
    background: #000000;
    border-radius: 23px;
    font-size: 14px;
    color: #fff;
    display: block;
    margin: 18px auto 18px;
    border: 1px solid #000000;
  }

  .InputStyle {
    width: 260px;
    height: 38px;
    background: #fff;
    border-radius: 23px;
    font-size: 14px;
    color: rgb(15, 15, 15);
    display: block;
    margin: 14px auto 14px;
    border: 1px solid #000000;
    padding: 0 20px;

    &:focus {
      outline: none;
    }
  }
}

.searchBtn {
  width: 260px;
  height: 38px;
  background: #000000;
  border: 1px solid #000000;
  color: #ffffff;
  border-radius: 23px;
  font-size: 14px;
  display: block;
  margin: 14px auto 14px;
  padding: 0 20px;

  &:hover {
    border: 1px solid #000000;
  }
}














/* Custom modal css open */
.modalContainer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  height: 100vh;
}

.modalContainer.show {
  opacity: 1;
  pointer-events: visible;
}

.modalContent {
  border-radius: 8px;
  width: 460px;
  max-width: 96%;
  background-color: #ffffff;
  color: #000;
  transform: translateY(-200px);
  transition: all 0.3s ease-in-out;
}

.modalContainer.show .modalContent {
  transform: translateY(0);
}
.product--img .slick-prev {
  left: -42px !important;
}
.product--img .slick-next {
  right: -35px !important;
}
.product--img .slick-prev:before, .slick-next:before {
  color: black !important;
  font-size: 25px !important; 
  position: relative;
}
.product--img .slick-prev:before {
  right: 0px;
}
.product--img .slick-next:before  {
 left: 0px;
}
.modalHeader {
  background: #fcff00;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  margin-bottom: 14px;
}

.modalTitle {
  margin: 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  text-align: center;
  color: #1e1e1e;
}

.CloseBtn {
  padding: 0 !important;
  background: none;
  border: 0;
  width: 28px;
}

.modalBody {
  padding: 25px 24px;
  text-align: center;
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: 24px;
}

.modalFooter .PrimaryBtn {
  background-color: #fff;
  border-radius: 6px;
  color: #000;
  padding: 10px 24px;
  width: auto;
  height: auto;
  border: 1px solid #000;
  margin-left: 14px;
}

.modalFooter .SecondaryBtn {
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  padding: 10px 24px;
  width: auto;
  height: auto;
  border: 1px solid #000;
  margin-left: 14px;
}

/* Custom modal css close */

.modalBody .red {
  margin: 0;
}

.modalBody h5 {
  font-weight: 700;
  font-size: 20px;
  color: #1e1e1e;
}

.refund-error {
  border: 0.5px solid #b3b3b3;
  border-radius: 10px;
  padding: 17px 20px;
}

.refund-error p {
  font-weight: 400;
  font-size: 18px;
}

.modaHeader {
  background: #FCFF00;
  border-radius: 8px 8px 0px 0px;
  padding: 23px 22px;
}

.modaHeader h5 {
  font-weight: 700;
  font-size: 21px;
}


.thankyouModal {
  .modal-header {
    justify-content: center;
    text-align: center;
    padding: 25px;
  }

  .modal-body {
    text-align: center;
    padding: 25px;
  }

  .thankyougif {
    max-width: 200px;
    height: 200px;
    width: 100%;
    margin: 0 auto 20px;

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    color: #16A070;
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 8px;

    .icon {
      max-width: 38px;
      height: 38px;
      margin-right: 8px;

    }

    .successIcon {
      max-width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    color: #27272B;
    margin: 0
  }

  .trackOrder {
    border-radius: 4px;
    border: 1px solid #16A070;
    background: #16A070;
    color: #fff;
    padding: 5px 20px;
    margin-top: 50px;
  }
}

.clear-cart {
  .modal-content {
    padding: 15px !important;
  }
}

.clearcartmodal {
  .clearCart {
    img {
      display: flex;
      margin: 0 auto 15px;
      max-width: 95px;
    }

    .cart-title {
      font-weight: 400;
      text-align: center;
      font-size: 18px;
    }
  }

  .modal-content {
    padding: 38px 20px;
    border-radius: 18px;
  }

  .modal-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .confirmLogout {
    img {
      display: flex;
      margin: 0 auto;
    }

    .confirm-subtitle {
      color: #2078C2;
      font-family: Roboto;
      text-align: center;
      position: relative;
      padding-bottom: 15px;
      padding-top: 15px;

      &::after {
        position: absolute;
        content: "";
        background: url(../../images/logout-bg.svg) no-repeat;
        width: 100%;
        height: 8px;
        background-size: 100%;
        bottom: 0;
        left: 0;
      }
    }
  }

  .confirmtitle {
    font-size: 17px;
    font-weight: 500;
    font-family: Roboto;
    margin-bottom: 15px;
  }

  .modal-body {
    text-align: center;
    padding: 40px 20px;
    font-weight: 500;
    font-size: 18px;
  }

  .modal-footer {
    justify-content: center;

    button {
      border-radius: 7px;
      padding: 5px 30px;
      background: #fff;
      border: 1px solid #000;
      max-width: 110px;
      width: 100%;

      &:last-child {
        border: 1px solid #005298;
        color: #ffffff;
        background: #005298;
      }
    }
  }
}

.productDetailModal {
  p {
    max-height: 160px;
    overflow-y: scroll;
  }

  .product--img {
    width: 140px;
    min-height: 140px;
    margin: 0 auto 30px;
  .slick-track {
    display: flex;
  }
    .slick-slider {
      height: 100%;
    }
    .slick-slide {
      height: inherit;
    }
    .slick-slide>div {
      height: 100%;
    }
    .prodcutImage {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.login-cross {
  right: 15px;
  position: absolute;
  top: 15px;
}