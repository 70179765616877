body {
  font-family: Roboto;
}
.ModalHeader {
    background: rgba(98, 182, 254, 0.2);
    border-radius: 0.5em 0.5em 0 0;
    padding: 14px 20px;
    h4 {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      margin: 0;
      font-family: "Nunito", sans-serif;
    }
  }
  .ModalBody {
    padding: 30px 24px;
  }
  .ParagraphMd {
    font-weight: 500;
    color: #000;
    font-size: 18px;
  }
  
  .OtpFlex {
    display: flex;
    align-items: center;
    width: 268px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0 10px;
    margin:30px auto;
    border-radius: 10px;
    h5 {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #000000;
      width: 60px;
      font-family: "Nunito", sans-serif;
    }
  }
  
  .InputStyleOtp {
    border: 0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #000000;
    height: 38px;
    border-radius: 10px;
    width: calc(100% - 60px);
    font-family: "Nunito", sans-serif;
  
    &:focus {
      outline: none;
    }
  }
  
  .OtpBtn {
    width: 268px;
    height: 44px;
    background: #fdb12a;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    border: 1px solid #fdb12a;
  
    // &:hover,
    // &:focus {
    //   background: #fdb12a;
    //   color: #000000;
    // }
  }
  .OtpBtn:disabled,
  .OtpBtn[disabled] {
    border: 1px solid #f1f1f1;
    background: #f1f1f1;
    color: #666666;
    cursor: not-allowed;
  }
  
  .InputFlex {
    display: flex;
    justify-content: space-between;
  
    .inputflexBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
  
      input {
        width: 40px !important;
        height: 43px;
        border: 0.800000011920929px solid #707070;
        border-radius: 6px;
        margin: 0 7px;
        font-family: "Nunito", sans-serif;
        font-size: 25px;
        font-weight: 500;
        &:focus {
          outline: none;
        }
      }
    }
  
    .OptDigits {
      width: 52px;
      height: 52px;
      background: #ffffff;
      border: 1px solid #707070;
      border-radius: 10px;
      padding: 0 10px;
      text-align: center;
      letter-spacing: 0px;
      color: #000000;
      font-family: "Nunito", sans-serif;
      font-size: 34px;
      font-weight: 700;
  
      &:focus {
        outline: none;
      }
    }
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .ParagraphMd {
    font-weight: 500;
    color: #000;
    font-size: 18px;
  }
  
  .RecievedOtp {
    margin: 10px 0;
    font-weight: 500;
    text-align: end;
  
    button {
      color: #005298;
      background: none;
      padding: 0;
      border: 0;
      border-bottom: 1px solid #005298;
    }
  }
  